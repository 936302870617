import React from 'react'

export default function AvatarIcon({ color = '#000', width = '18px', height = '18px' }) {
  return (
    <svg
      viewBox='0 0 26 26'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
      className='svgIcon iconAvatar'
      style={{ width, height }}
    >
      <title>Sign Up</title>
      <path
        d='M3.82805 20.7383C6.53689 19.2072 9.6664 18.3333 13 18.3333C16.3336 18.3333 19.4631 19.2072 22.172 20.7383M17 10.3333C17 12.5425 15.2091 14.3333 13 14.3333C10.7909 14.3333 9 12.5425 9 10.3333C9 8.12419 10.7909 6.33333 13 6.33333C15.2091 6.33333 17 8.12419 17 10.3333ZM25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13C1 6.37258 6.37258 1 13 1C19.6274 1 25 6.37258 25 13Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
